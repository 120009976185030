<template>
  <div class="legals">
    <b-container class="legals-content text-center">
      <h2>Colaboración con la justicia</h2>

      <b-row class="d-flex justify-content-center">
        <b-col md="12" class="information mt-5" style="text-align: justify">
          <p>
            Por instrucciones del Instituto Federal de Telecomunicaciones, se
            publica la dirección de correo electrónico que más adelante se
            señala, con la finalidad de que se puedan dirigir los requerimientos
            de las autoridades que estén facultadas en términos del “ACUERDO
            mediante el cual el Pleno del Instituto Federal de
            Telecomunicaciones expide los Lineamientos de Colaboración en
            Materia de Seguridad y Justicia y modifica el plan técnico
            fundamental de numeración, publicado el 21 de junio de 1996”,
            publicado en el Diario Oficial de la Federación el 2 de diciembre de
            2015, para lo cual ya se proponen los formatos, sistemas y modo de
            comunicación más eficiente:
          </p>

          <br />

          <h3>
            <small>Dirección de Correo Electrónico: </small>
            <br />

            legal@beetelecom.mx
          </h3>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Colaboracion",

  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
.legals {
  background: #fecd00;
  height: 100vh;
}

.legals .legals-content {
  padding-top: 150px;
  padding-bottom: 150px;
}

.legals .legals-content h2 {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 40px;
  text-shadow: 1px 1px black, -1px -1px #ffffff;
}

.legals .legals-content p {
  font-weight: 600;
  font-size: 15px;
}

.legals .legals-content p b {
  color: #ffffff;
  font-size: 17.5px;
}

.legals .legals-content h3 {
  font-weight: 600;
  font-size: 25px;
  text-align: center;
}

.legals .legals-content h3 small {
  font-size: 15px;
}
</style>
